import React from 'react';
import '@assets/styles/Home.css';

const Home = () => {
  return (
    <div className="home">
    </div>
  );
};

export default Home;
