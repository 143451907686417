import React, { useEffect, useState } from "react";
import { getContainers, getFluxProducers } from "@services/api_containers";
import "./ContainerList.css";

const ContainerList = () => {
  const [containers, setContainers] = useState([]);
  const [fluxProducers, setFluxProducers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const containersData = await getContainers();
        const fluxProducersData = await getFluxProducers();
        setContainers(containersData.data); // 假设API返回的数据结构中包含 data
        setFluxProducers(fluxProducersData.data);
      } catch (error) {
        console.error("Error fetching container data:", error);
      }
    };

    // 初次加载数据
    fetchData();

    // 设置定时器，每两秒刷新一次数据
    const intervalId = setInterval(() => {
      fetchData();
    }, 2000);

    // 清理定时器
    return () => clearInterval(intervalId);
  }, []);

  const getStatusModifier = (status, isBusy) => {
    if (status !== "running") return "container-item--stopped";
    return isBusy ? "container-item--busy" : "container-item--running";
  };

  return (
    <div className="container-list">
      <h1 className="container-list__title">Container Status</h1>

      <section className="container-list__section">
        <h2 className="container-list__section-title">Containers</h2>
        {containers.length > 0 ? (
          <div className="container-list__group">
            {containers.map((container) => (
              <div
                key={container.id}
                className={`container-item ${getStatusModifier(
                  container.status,
                  container.is_busy
                )}`}
              >
                <h3 className="container-item__id">Container ID: {container.id}</h3>
                <p className="container-item__info">
                  <strong>Status:</strong> {container.status}
                </p>
                <p className="container-item__info">
                  <strong>Busy:</strong> {container.is_busy ? "Yes" : "No"}
                </p>
                <p className="container-item__info">
                  <strong>Device ID:</strong> {container.device_id}
                </p>
                <p className="container-item__info">
                  <strong>Last Generate Time:</strong> {container.last_generate_time}
                </p>
                <p className="container-item__info">
                  <strong>Port:</strong> {container.port}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <p className="container-list__empty">No containers available.</p>
        )}
      </section>

      <section className="container-list__section">
        <h2 className="container-list__section-title">Flux Producers</h2>
        {fluxProducers.length > 0 ? (
          <div className="container-list__group">
            {fluxProducers.map((producer) => (
              <div
                key={producer.uuid}
                className={`container-item ${getStatusModifier(
                  producer.status,
                  producer.is_busy
                )}`}
              >
                <h3 className="container-item__id">Producer UUID: {producer.uuid}</h3>
                <p className="container-item__info">
                  <strong>Status:</strong> {producer.status}
                </p>
                <p className="container-item__info">
                  <strong>Busy:</strong> {producer.is_busy ? "Yes" : "No"}
                </p>
                <p className="container-item__info">
                  <strong>Device ID:</strong> {producer.device_id}
                </p>
                <p className="container-item__info">
                  <strong>Pipeline:</strong> {producer.pipeline}
                </p>
                <p className="container-item__info">
                  <strong>Base Model:</strong> {producer.basemodel}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <p className="container-list__empty">No flux producers available.</p>
        )}
      </section>
    </div>
  );
};

export default ContainerList;
