import React, { useEffect, useState } from 'react';
import PlanForm from '@components/Plan/PlanForm';
import PlanTable from '@components/Plan/PlanTable';
import './Plan.css';
import { fetchPlans, createPlan, updatePlan } from '@services/api_plan';

const Plan = () => {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newPlan, setNewPlan] = useState({
    project: '',
    classification_L1: '',
    classification_L2: '',
    classification_L3: '',
    details: '',
    importance: '',
    status: '',
    deadline: '',
    remark: '',
    PIC: ''
  });
  const [showForm, setShowForm] = useState(false);
  const [currentProject, setCurrentProject] = useState('');
  const [editingPlanId, setEditingPlanId] = useState(null);
  const [filterL1, setFilterL1] = useState('');
  const [filterL2, setFilterL2] = useState('');

  useEffect(() => {
    const getPlans = async () => {
      try {
        const data = await fetchPlans();
        // Sort the plans
        data.sort((a, b) => {
          if (a.classification_L1 !== b.classification_L1) {
            return a.classification_L1.localeCompare(b.classification_L1);
          }
          return a.classification_L2.localeCompare(b.classification_L2);
        });
        setPlans(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    getPlans();
  }, []);
  

  const handleInputChange = (e, planId) => {
    const { name, value } = e.target;
    setPlans((prevPlans) =>
      prevPlans.map((plan) =>
        plan.id === planId ? { ...plan, [name]: value } : plan
      )
    );
  };

  const handleNewPlanChange = (e) => {
    const { name, value } = e.target;
    setNewPlan({ ...newPlan, [name]: value });
  };

  const handleSavePlan = async (planId) => {
    const updatedPlan = plans.find((plan) => plan.id === planId);
    try {
      await updatePlan(planId, updatedPlan);
      setEditingPlanId(null);
    } catch (error) {
      console.error("Error updating plan:", error);
    }
  };

  const handleAddPlan = async (e) => {
    e.preventDefault();
    try {
      const createdPlan = await createPlan(newPlan);
      setPlans([...plans, createdPlan]);
      setNewPlan({
        project: '',
        classification_L1: '',
        classification_L2: '',
        classification_L3: '',
        details: '',
        importance: '',
        status: '',
        deadline: '',
        remark: '',
        PIC: ''
      });
      setShowForm(false);
    } catch (error) {
      console.error("Error creating plan:", error);
    }
  };

  const handleDoubleClick = (planId) => {
    setEditingPlanId(planId);
  };

  const openFormForProject = (project) => {
    setCurrentProject(project);
    setNewPlan((prevNewPlan) => ({ ...prevNewPlan, project }));
    setShowForm(true);
  };

  const uniqueL1 = [...new Set(plans.map(plan => plan.classification_L1))];
  const uniqueL2 = [...new Set(plans.filter(plan => plan.classification_L1 === filterL1 || !filterL1).map(plan => plan.classification_L2))];

  const filteredPlans = plans.filter(plan =>
    (filterL1 ? plan.classification_L1 === filterL1 : true) &&
    (filterL2 ? plan.classification_L2 === filterL2 : true)
  );

  const plansByProject = filteredPlans.reduce((acc, plan) => {
    (acc[plan.project] = acc[plan.project] || []).push(plan);
    return acc;
  }, {});

  const getRowSpan = (fields, values, plans, startIndex) => {
    let count = 0;
    for (let i = startIndex; i < plans.length; i++) {
      let match = true;
      for (let j = 0; j < fields.length; j++) {
        if (plans[i][fields[j]] !== values[j]) {
          match = false;
          break;
        }
      }
      if (match) {
        count++;
      } else {
        break;
      }
    }
    return count;
  };
  

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="plan">
      <h2>计划列表</h2>

      {Object.entries(plansByProject).map(([project, projectPlans]) => (
        <div key={project} className="project-group">
          <h3>{project}</h3>
          <button className="add-plan-button" onClick={() => openFormForProject(project)}>
            {showForm && currentProject === project ? "关闭表单" : "添加新计划"}
          </button>

          {showForm && currentProject === project && (
            <PlanForm
              newPlan={newPlan}
              handleNewPlanChange={handleNewPlanChange}
              handleAddPlan={handleAddPlan}
              project={project}
            />
          )}

          <PlanTable
            projectPlans={projectPlans}
            editingPlanId={editingPlanId}
            handleDoubleClick={handleDoubleClick}
            handleInputChange={handleInputChange}
            handleSavePlan={handleSavePlan}
            getRowSpan={getRowSpan}
          />
        </div>
      ))}
    </div>
  );
};

export default Plan;
