import axios from 'axios';

const API_BASE_URL = 'https://backend.temp.fit';

const api = axios.create({
  baseURL: API_BASE_URL,
});

api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('access_token'); // 假设token存储在localStorage中
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

export const getLabelData = async (group) => {
    try {
      const response = await api.get(`/api/labels/categories/${group}`);
      return response.data.data;
    } catch (error) {
      console.error('Error fetching label data:', error);
      throw error;
    }
  };
  
  export const addLabelCategory = async (group, categoryName) => {
    try {
      const response = await api.post(`/api/labels/add-category/${group}`, { name: categoryName });
      return response.data.data;
    } catch (error) {
      console.error('Error adding label category:', error);
      throw error;
    }
  };
  
  export const addLabelSubcategory = async (group, categoryName, subcategoryName, subcategoryValue) => {
    try {
      const response = await api.post(`/api/labels/add-subcategory/${group}/${categoryName}`, {
        name: subcategoryName,
        value: subcategoryValue,
      });
      return response.data.data;
    } catch (error) {
      console.error('Error adding label subcategory:', error);
      throw error;
    }
  };

  export const getLabelGroups = async () => {
    try {
      const response = await api.get('/api/labels/label-groups');
      return response.data;
    } catch (error) {
      console.error('Error fetching label groups:', error);
      throw error;
    }
  };
  
  export const addLabelGroup = async (groupName) => {
    try {
      const response = await api.post('/api/labels/add-label-group', null, { params: { group_name: groupName } });
      return response.data;
    } catch (error) {
      console.error('Error adding label group:', error);
      throw error;
    }
  };

  export const updateImageLabel = async (data) => {
    const response = await api.post('/api/labels/update', data);
    return response.data;
  };

  export const getImageLabels = async (dataset, image) => {
    try {
      const response = await api.get(`/api/labels/image-labels/${dataset}/${image}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  export const generateExcel = async (dataset, labelGroup) => {
    try {
      const response = await api.get(`/api/labels/generate-excel/${dataset}/${labelGroup}`, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${dataset}_${labelGroup}_labels.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error generating Excel file:', error);
      alert(`Error generating Excel file: ${error.response?.data?.detail || error.message}`);
    }
  };
  