import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import { useDropzone } from 'react-dropzone';
import ImageSection from '@components/Label/ImageSection';
import LabelSection from '@components/Label/LabelSection';
import ListWithBackButton from '@components/Label/ListWithBackButton';
import { getDatasets, getDatasetFiles, getFile, uploadImages, deleteDataset } from '@services/api';
import { getLabelGroups } from '@services/labelApi';
import '@assets/styles/Label.css';
import Login from '@components/Login';

Modal.setAppElement('#root');

const Label = () => {
  const [datasets, setDatasets] = useState([]);
  const [currentList, setCurrentList] = useState([]);
  const [dataSetsInfo, setDataSetsInfo] = useState([]);
  const [currentPath, setCurrentPath] = useState('datasets');
  const [images, setImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [currentDatasetLabelGroup, setCurrentDatasetLabelGroup] = useState('');
  const [labelGroups, setLabelGroups] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedLabelGroup, setSelectedLabelGroup] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const checkAuthentication = () => {
    const token = localStorage.getItem('access_token');
    setIsAuthenticated(!!token);
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  const fetchData = async () => {
    if (isAuthenticated) {
      let [datasetData, labelGroupData] = await Promise.all([getDatasets(), getLabelGroups()]);
      setDataSetsInfo(datasetData["info"])
      setDatasets(datasetData["datasets"]);
      setCurrentList(datasetData["datasets"]);
      setLabelGroups(labelGroupData.label_groups);
    }
  };

  useEffect(() => {
    fetchData();
  }, [isAuthenticated]);

  const fetchImage = useCallback(async (datasetName, fileName) => {
    const fileUrl = await getFile(datasetName, fileName);
    return { filename: fileName, url: fileUrl, dataset: datasetName };
  }, []);

  const handleListItemClick = async (item) => {
    console.log(item)
    if (currentPath === 'datasets') {
      setCurrentPath(item);
      const { files, info } = await getDatasetFiles(item);
      setCurrentList(files);
      setCurrentDatasetLabelGroup(info.label_group || '');

      if (files.length > 0) {
        const firstImage = await fetchImage(item, files[0]);
        setImages([firstImage]);
        setCurrentImageIndex(0);
      }
    } else {
      const imageIndex = currentList.indexOf(item);
      console.log(imageIndex)
      const image = await fetchImage(currentPath, item);
      setImages((prevImages) => {
        const newImages = [...prevImages];
        newImages[imageIndex] = image;
        return newImages;
      });
      setCurrentImageIndex(imageIndex);
    }
  };

  const handleBackClick = () => {
    setCurrentDatasetLabelGroup('');
    setCurrentPath('datasets');
    setCurrentList(datasets);
    setImages([]);
    setCurrentImageIndex(0);
  };

  const onDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
    setModalIsOpen(true);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: false,
    noKeyboard: false,
  });

  const handleImageUpload = async () => {
    setModalIsOpen(false);
    const onUploadProgress = (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setUploadProgress(percentCompleted);
    };

    try {
      const response = await uploadImages(files, onUploadProgress, selectedLabelGroup);
      const uploadedImages = response.files.map(file => ({
        filename: file,
        url: `/api/files/${currentPath}/${file}`,
      }));
      setImages([...images, ...uploadedImages]);
      setUploadProgress(0);

      const updatedDatasets = await getDatasets();
      setDatasets(updatedDatasets);
      setCurrentList(updatedDatasets);

      if (currentPath !== 'datasets') {
        const { files } = await getDatasetFiles(currentPath);
        setCurrentList(files);
      }
    } catch (error) {
      console.error('Error uploading images:', error);
    }
  };

  const handleImageNavigation = async (direction) => {
    if (currentList.length > 0) {
      const newIndex = (currentImageIndex + direction + currentList.length) % currentList.length;
      setCurrentImageIndex(newIndex);

      const indicesToCache = [
        newIndex,
        (newIndex + 1) % currentList.length,
        (newIndex - 1 + currentList.length) % currentList.length
      ];

      indicesToCache.forEach(async (index) => {
        if (!images[index]) {
          const image = await fetchImage(currentPath, currentList[index]);
          setImages((prevImages) => {
            const newImages = [...prevImages];
            newImages[index] = image;
            return newImages;
          });
        }
      });
    }
  };

  const handleLabelGroupChange = (e) => {
    setSelectedLabelGroup(e.target.value);
  };

  const handleDatasetDelete = async (datasetName) => {
    try {
      await deleteDataset(datasetName);
      const updatedDatasets = await getDatasets();
      setDatasets(updatedDatasets["datasets"]);
      setCurrentList(updatedDatasets["datasets"]);
      setDataSetsInfo(updatedDatasets["info"])
      setImages([]);
      setCurrentImageIndex(0);
      setCurrentPath('datasets');
    } catch (error) {
      console.error('Error deleting dataset:', error);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'a' || event.key === 'A') {
        handleImageNavigation(-1);
      } else if (event.key === 'd' || event.key === 'D') {
        handleImageNavigation(1);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleImageNavigation]);

  return (
    <div className="label-container">
      {!isAuthenticated ? (
        <Login onClose={() => setIsAuthenticated(true)} />
      ) : (
        <>
          <div className="left-section">
            <ListWithBackButton
              currentPath={currentPath}
              onBackClick={handleBackClick}
              currentList={currentList}
              onListItemClick={handleListItemClick}
              currentImageIndex={currentImageIndex}
              info={dataSetsInfo}
              setCurrentImageIndex={setCurrentImageIndex}
              onDelete={handleDatasetDelete}
            />
            <div className="upload-container" {...getRootProps()} style={{ border: '2px dashed #cccccc', padding: '20px', textAlign: 'center', display: currentPath === 'datasets' ? 'block' : 'none' }}>
              <input {...getInputProps()} directory="" webkitdirectory="" type="file" multiple style={{ height: '30px' }}/>
              <p>拖拽文件夹到此处或点击选择文件夹进行上传</p>
              {uploadProgress > 0 && (
                <div className="progress-bar">
                  <div className="progress" style={{ width: `${uploadProgress}%` }}>
                    {uploadProgress}%
                  </div>
                </div>
              )}
            </div>
            <ImageSection
              images={images}
              currentImageIndex={currentImageIndex}
              handleNextImage={() => handleImageNavigation(1)}
              setImages={setImages}
              handlePreviousImage={() => handleImageNavigation(-1)}
            />
          </div>

          <div className="right-section">
            <LabelSection 
              labelGroup={currentDatasetLabelGroup}
              datasetName={currentPath}
              currentImage={currentList[currentImageIndex]}
            />
          </div>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            contentLabel="选择标签组"
            className="modal"
            overlayClassName="overlay"
          >
            <h2>选择标签组</h2>
            <select value={selectedLabelGroup} onChange={handleLabelGroupChange}>
              <option value="">选择标签组</option>
              {labelGroups.map((group, index) => (
                <option key={index} value={group}>
                  {group}
                </option>
              ))}
            </select>
            <button onClick={handleImageUpload}>确认上传</button>
            <button onClick={() => setModalIsOpen(false)}>取消</button>
          </Modal>
        </>
      )}
    </div>
  );
};

export default Label;
