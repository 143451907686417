import React, { useState, useRef, useEffect } from 'react';
import '@assets/styles/Label/ListWithBackButton.css';

const ListWithBackButton = ({ currentPath, onBackClick, currentList,info, onListItemClick, currentImageIndex, setCurrentImageIndex, onDelete }) => {
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [datasetToDelete, setDatasetToDelete] = useState(null);
  const [jumpId, setJumpId] = useState(''); // 新增用于存储输入的ID
  const listItemRefs = useRef([]); // 用于存储列表项的refs

  useEffect(() => {
    // 每当currentImageIndex更新时，确保对应的列表项在可视区域内
    if (listItemRefs.current[currentImageIndex]) {
      listItemRefs.current[currentImageIndex].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [currentImageIndex]);

  const handleListDeleteClick = (event, item) => {
    event.stopPropagation();
    setDatasetToDelete(item);
    setConfirmVisible(true);
  };

  const handleConfirmDelete = async () => {
    try {
      if (datasetToDelete) {
        await onDelete(datasetToDelete); // 调用传递的删除处理函数
      }
    } catch (error) {
      console.error('Error deleting dataset:', error);
    } finally {
      setConfirmVisible(false);
      setDatasetToDelete(null);
    }
  };

  const handleLocateClick = () => {
    // 手动触发当前项滚动到可视区域
    if (listItemRefs.current[currentImageIndex]) {
      listItemRefs.current[currentImageIndex].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const handleJumpToId = () => {
    const id = parseInt(jumpId, 10);
    if (id >= 0 && id < currentList.length) {
      setCurrentImageIndex(id); // 更新currentImageIndex
      listItemRefs.current[id].scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else {
      alert('无效的ID');
    }
  };

  return (
    <div className="list-with-back-button">
      {currentPath !== 'datasets' && (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
          <div className="back-button" onClick={onBackClick}>
            ← Back
          </div>
          <button className="locate-button" onClick={handleLocateClick} style={{ marginLeft: '10px' }}>
            定位
          </button>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input 
              type="text" 
              value={jumpId} 
              onChange={(e) => setJumpId(e.target.value)} 
              placeholder="输入ID" 
              style={{ marginRight: '10px', marginLeft: '10px' }}
            />
            <button onClick={handleJumpToId}>跳转到ID</button>
          </div>
        </div>
      )}
      
      <div className="list-container">
        {currentList.map((item, index) => (
          <div
            key={index}
            ref={(el) => listItemRefs.current[index] = el} // 将每个列表项的DOM节点存储到refs中
            className={`list-item ${index === currentImageIndex ? 'active' : ''}`}
            onClick={() => onListItemClick(item)}
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <span>{index}</span> {/* 显示ID */}
            
            <span>{item}</span>
            {currentPath === 'datasets' && (
              <>
              <span>{info[index].labeled_count}/{info[index].image_count} 删除数：{info[index].delete_count}</span> {/* 仅在 currentPath === 'datasets' 时显示标注数/图片数 */}
              <button className="fill-button" onClick={(event) => handleListDeleteClick(event, item.name)}>删除</button>
            </>
            )}
          </div>
        ))}
      </div>

      {confirmVisible && (
        <div className="confirm-dialog">
          <p>确定要删除该数据集吗？</p>
          <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <button onClick={handleConfirmDelete}>确认</button>
            <button onClick={() => setConfirmVisible(false)}>取消</button>
          </span>
        </div>
      )}
    </div>
  );
};

export default ListWithBackButton;
