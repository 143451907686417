import React from 'react';
import ReactMarkdown from 'react-markdown';

const PlanTable = ({ projectPlans, editingPlanId, handleDoubleClick, handleInputChange, handleSavePlan, getRowSpan }) => {
  return (
    <table className="plan-table">
      <thead>
        <tr>
          <th>一级分类</th>
          <th>二级分类</th>
          <th>三级分类</th>
          <th>详细</th>
          <th>重要性</th>
          <th>状态</th>
          <th>截止</th>
          <th>备注</th>
          <th>PIC</th>
        </tr>
      </thead>
      <tbody>
        {projectPlans.map((plan, index) => {
          // 计算 classification_L1 的合并行数
          const l1RowSpan =
            index === 0 || plan.classification_L1 !== projectPlans[index - 1].classification_L1
              ? getRowSpan(['classification_L1'], [plan.classification_L1], projectPlans, index)
              : 0;

          // 计算 classification_L2 的合并行数
          const l2RowSpan =
            index === 0 ||
            plan.classification_L1 !== projectPlans[index - 1].classification_L1 ||
            plan.classification_L2 !== projectPlans[index - 1].classification_L2
              ? getRowSpan(
                  ['classification_L1', 'classification_L2'],
                  [plan.classification_L1, plan.classification_L2],
                  projectPlans,
                  index
                )
              : 0;

          return (
            <tr key={plan.id} className="plan-item" onDoubleClick={() => handleDoubleClick(plan.id)}>
              {l1RowSpan > 0 && (
                <td rowSpan={l1RowSpan}>
                  <input
                    type="text"
                    name="classification_L1"
                    value={plan.classification_L1}
                    onChange={(e) => handleInputChange(e, plan.id)}
                    onBlur={() => handleSavePlan(plan.id)}
                    readOnly={editingPlanId !== plan.id}
                  />
                </td>
              )}
              {l2RowSpan > 0 && (
                <td rowSpan={l2RowSpan}>
                  <input
                    type="text"
                    name="classification_L2"
                    value={plan.classification_L2}
                    onChange={(e) => handleInputChange(e, plan.id)}
                    onBlur={() => handleSavePlan(plan.id)}
                    readOnly={editingPlanId !== plan.id}
                  />
                </td>
              )}
              <td>
                <input
                  type="text"
                  name="classification_L3"
                  value={plan.classification_L3 || ''}
                  onChange={(e) => handleInputChange(e, plan.id)}
                  onBlur={() => handleSavePlan(plan.id)}
                  readOnly={editingPlanId !== plan.id}
                />
              </td>
              <td>
                {editingPlanId === plan.id ? (
                  <textarea
                    name="details"
                    value={plan.details || ''}
                    onChange={(e) => handleInputChange(e, plan.id)}
                    onBlur={() => handleSavePlan(plan.id)}
                    className="editable details-textarea"
                  />
                ) : (
                  <div className="markdown-content">
                    <ReactMarkdown>{plan.details || ''}</ReactMarkdown>
                  </div>
                )}
              </td>
              <td>
                <input
                  type="text"
                  name="importance"
                  value={plan.importance || ''}
                  onChange={(e) => handleInputChange(e, plan.id)}
                  onBlur={() => handleSavePlan(plan.id)}
                  readOnly={editingPlanId !== plan.id}
                />
              </td>
              <td>
                <input
                  type="text"
                  name="status"
                  value={plan.status || ''}
                  onChange={(e) => handleInputChange(e, plan.id)}
                  onBlur={() => handleSavePlan(plan.id)}
                  readOnly={editingPlanId !== plan.id}
                />
              </td>
              <td>
                <input
                  type="datetime-local"
                  name="deadline"
                  value={plan.deadline || ''}
                  onChange={(e) => handleInputChange(e, plan.id)}
                  onBlur={() => handleSavePlan(plan.id)}
                  readOnly={editingPlanId !== plan.id}
                />
              </td>
              <td>
                <input
                  type="text"
                  name="remark"
                  value={plan.remark || ''}
                  onChange={(e) => handleInputChange(e, plan.id)}
                  onBlur={() => handleSavePlan(plan.id)}
                  readOnly={editingPlanId !== plan.id}
                />
              </td>
              <td>
                <input
                  type="text"
                  name="PIC"
                  value={plan.PIC || ''}
                  onChange={(e) => handleInputChange(e, plan.id)}
                  onBlur={() => handleSavePlan(plan.id)}
                  readOnly={editingPlanId !== plan.id}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default PlanTable;
