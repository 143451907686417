import React, { useEffect, useState } from 'react';
import { Table, Statistic, Row, Col, Card } from 'antd';
import { Area } from '@ant-design/plots';
import moment from 'moment';
import { getGenerationRequests } from '@services/api_backstage';

const GenerationRequestManagement = () => {
  const [generationRequests, setGenerationRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20, // 减小分页大小以便适应页面
    total: 0,
  });
  const [chartData, setChartData] = useState([]);
  const [totalRequests, setTotalRequests] = useState(0);
  const [successfulRequests, setSuccessfulRequests] = useState(0);
  const [failedRequests, setFailedRequests] = useState(0);

  const fetchGenerationRequests = async () => {
    setLoading(true);
    try {
      const response = await getGenerationRequests({
        skip: 0,
        limit: 10000,
      });
      const data = response.data;
  
      setGenerationRequests(data.generation_requests);
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: data.total,
      }));
  
      setTotalRequests(data.total);
  
      const successCount = data.generation_requests.filter(
        (req) => req.status === 'success'
      ).length;
      const processingCount = data.generation_requests.filter(
        (req) => req.status === 'processing'
      ).length;
      setSuccessfulRequests(successCount);
      setFailedRequests(data.total - successCount - processingCount);
  
      const requestsByDate = {};
  
      data.generation_requests.forEach((req) => {
        const date = moment(req.created_at).format('YYYY-MM-DD');
        if (!requestsByDate[date]) {
          requestsByDate[date] = {
            date,
            total: 0,
            success: 0,
            failed: 0,
            processing: 0,
          };
        }
        requestsByDate[date].total += 1;
        if (req.status === 'success') {
          requestsByDate[date].success += 1;
        } else if (req.status === 'failed') {
          requestsByDate[date].failed += 1;
        } else if (req.status === 'processing') {
          requestsByDate[date].processing += 1;
        }
      });
  
      const chartData = Object.values(requestsByDate).sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      );
  
      const stackedChartData = [];
      chartData.forEach((item) => {
        stackedChartData.push({
          date: item.date,
          type: '成功',
          count: item.success,
        });
        stackedChartData.push({
          date: item.date,
          type: '失败',
          count: item.failed,
        });
        stackedChartData.push({
          date: item.date,
          type: '处理中',
          count: item.processing,
        });
      });
  
      setChartData(stackedChartData);
    } catch (error) {
      console.error('Failed to fetch generation requests', error);
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    fetchGenerationRequests();
  }, []);

  const columns = [
    {
      title: 'UUID',
      dataIndex: 'uuid',
      key: 'uuid',
    },
    {
      title: '用户名',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: '提示词',
      dataIndex: 'prompt',
      key: 'prompt',
      ellipsis: true,
    },
    {
      title: '请求类型',
      dataIndex: 'request_type',
      key: 'request_type',
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <span style={{ color: status === 'success' ? 'green' : 'red' }}>
          {status}
        </span>
      ),
    },
    {
      title: '创建时间',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (createdAt) => moment(createdAt).format('YYYY-MM-DD HH:mm'),
    },
  ];

  const handleTableChange = (pagination) => {
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  const chartConfig = {
    data: chartData,
    xField: 'date',
    yField: 'count',
    seriesField: 'type',
    isStack: true,
    smooth: true,
    tooltip: {
      showMarkers: false,
    },
    interactions: [{ type: 'active-region' }],
    legend: {
      position: 'top',
    },
  };

  return (
    <div>
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col span={4}>
          <Card>
            <Statistic title="总请求数" value={totalRequests} />
          </Card>
        </Col>
        <Col span={4}>
          <Card>
            <Statistic title="成功请求数" value={successfulRequests} />
          </Card>
        </Col>
        <Col span={4}>
          <Card>
            <Statistic title="失败请求数" value={failedRequests} />
          </Card>
        </Col>
      </Row>

      <div style={{ marginBottom: 20 }}>
        <Area {...chartConfig} style={{ height: 300 }} />
      </div>

      <Table
        columns={columns}
        dataSource={generationRequests}
        rowKey="uuid"
        loading={loading}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.total,
          showSizeChanger: true,
        }}
        onChange={handleTableChange}
        scroll={{ x: 'max-content' }}
      />
    </div>
  );
};

export default GenerationRequestManagement;
