import React, { useState } from 'react';
import '@assets/styles/Backstage.css';
import UserManagement from '@components/Backstage/UserManagement';
import ContainerList from '@components/Backstage/ContainerList';
import MembershipManagement from '@components/Backstage/MembershipManagement';
import GenerationRequestManagement from '@components/Backstage/GenerationRequestManagement';

const Backstage = () => {
  // 设置当前选中的标签页，默认值为 '用户'
  const [activeTab, setActiveTab] = useState('用户');

  // 根据选中的标签页渲染对应的组件
  const renderContent = () => {
    switch (activeTab) {
      case '用户':
        return <UserManagement />;
      case '会员':
        return <MembershipManagement />;
      case '生成记录':
        return <GenerationRequestManagement />;
      case '运行服务':
        return <ContainerList />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="tab-menu">
        <button onClick={() => setActiveTab('任务队列')} className={activeTab === '任务队列' ? 'active' : ''}>
        任务队列
        </button>
        <button onClick={() => setActiveTab('用户')} className={activeTab === '用户' ? 'active' : ''}>
          用户
        </button>
        <button onClick={() => setActiveTab('会员')} className={activeTab === '会员' ? 'active' : ''}>
        会员
        </button>
        <button onClick={() => setActiveTab('生成记录')} className={activeTab === '生成记录' ? 'active' : ''}>
        生成记录
        </button>
        <button onClick={() => setActiveTab('运行服务')} className={activeTab === '运行服务' ? 'active' : ''}>
        运行服务
        </button>
        <button onClick={() => setActiveTab('日志')} className={activeTab === '日志' ? 'active' : ''}>
        日志
        </button>
        <button onClick={() => setActiveTab('日志')} className={activeTab === '日志' ? 'active' : ''}>
        反馈信息
        </button>
      </div>
      
      <div className="tab-content">
        {renderContent()}
      </div>
    </div>
  );
};

export default Backstage;
