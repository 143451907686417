import React, { useEffect, useState } from 'react';
import { Table, Input, Button, Space, Form, Popconfirm, Statistic, Row, Col } from 'antd';
import { getUsers, updateUser } from '@services/api_backstage';
import { Line } from '@ant-design/plots';
import moment from 'moment';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [editingId, setEditingId] = useState(null);
  const [form] = Form.useForm();
  const [newUsersLastWeek, setNewUsersLastWeek] = useState(0);
  const [chartData, setChartData] = useState([]);
  
  // Fetch users
  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await getUsers({
        skip: (pagination.current - 1) * pagination.pageSize,
        limit: pagination.pageSize,
      });
      const data = response.data;
      setUsers(data.users);
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: data.total,
      }));
      
      // Calculate the number of new users in the past week
      const oneWeekAgo = moment().subtract(7, 'days');
      const newUsers = data.users.filter(user => moment(user.created_at).isAfter(oneWeekAgo));
      setNewUsersLastWeek(newUsers.length);
      
      // Prepare data for chart (e.g., number of users registered per day in the last 30 days)
      const registrations = data.users.map(user => moment(user.created_at).format('YYYY-MM-DD'));
      const dailyRegistrations = registrations.reduce((acc, date) => {
        acc[date] = (acc[date] || 0) + 1;
        return acc;
      }, {});
      
      const chartData = Object.keys(dailyRegistrations).map(date => ({
        date,
        count: dailyRegistrations[date],
      }));
      
      setChartData(chartData);
    } catch (error) {
      console.error('Failed to fetch users', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [pagination.current, pagination.pageSize]);

  const isEditing = (record) => record.id === editingId;

  const edit = (record) => {
    form.setFieldsValue({
      username: '',
      email: '',
      status: '',
      ...record,
    });
    setEditingId(record.id);
  };

  const cancel = () => {
    setEditingId(null);
  };

  const save = async (id) => {
    try {
      const row = await form.validateFields();
      const newData = [...users];
      const index = newData.findIndex((item) => id === item.id);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setUsers(newData);
        setEditingId(null);
        await updateUser(item.uuid, row);
      } else {
        newData.push(row);
        setUsers(newData);
        setEditingId(null);
      }
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      editable: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      editable: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      editable: true,
      render: (status) => (status === 'ACT' ? 'Active' : 'Inactive'),
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: 'Avatar',
      dataIndex: 'avatar_file_path',
      key: 'avatar_file_path',
      render: (avatar) => <img src={`https://backend.temp.fit/static/${avatar}`} alt="avatar" style={{ width: 40 }} />,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button onClick={() => save(record.id)} type="link">
              Save
            </Button>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <Button type="link">Cancel</Button>
            </Popconfirm>
          </span>
        ) : (
          <Button type="link" disabled={editingId !== null} onClick={() => edit(record)}>
            Edit
          </Button>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'status' ? 'select' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputType === 'text' ? (
              <Input />
            ) : (
              <Input />
            )}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const handleTableChange = (pagination) => {
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  // Line chart config for daily user registrations
  const chartConfig = {
    data: chartData,
    xField: 'date',
    yField: 'count',
    smooth: true,
  };

  return (
    <div>
      {/* Display user stats */}
      <Row gutter={16}>
        <Col span={8}>
          <Statistic title="Total Users" value={pagination.total} />
        </Col>
        <Col span={8}>
          <Statistic title="Users Registered in Last Week" value={newUsersLastWeek} />
        </Col>
      </Row>

      {/* Chart */}
      <div style={{ marginTop: 20, height: 300 }}>
        <Line {...chartConfig} />
      </div>

      {/* Table */}
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          columns={mergedColumns}
          dataSource={users}
          rowKey="id"
          loading={loading}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
          }}
          onChange={handleTableChange}
        />
      </Form>
    </div>
  );
};

export default UserManagement;
