import axios from 'axios';

const API_BASE_URL = 'https://backend.temp.fit';

const api = axios.create({
  baseURL: API_BASE_URL,
});

// ------------------------------
// Container Management Functions
// ------------------------------

// 获取所有容器信息
export const getContainers = async () => {
  return api.get('/back/containers/containers');
};

// 根据容器 ID 获取容器信息
export const getContainerById = async (id) => {
  return api.get(`/back/containers/containers/${id}`);
};

// 更新容器状态或信息
export const updateContainer = async (id, updateData) => {
  return api.put(`/back/containers/containers/${id}`, updateData);
};

// 删除容器
export const deleteContainer = async (id) => {
  return api.delete(`/back/containers/containers/${id}`);
};

// ------------------------------
// Flux Producer Management Functions
// ------------------------------

// 获取所有 Flux Producer 信息
export const getFluxProducers = async () => {
  return api.get('/back/containers/flux_producers');
};

// 根据 UUID 获取 Flux Producer 信息
export const getFluxProducerByUuid = async (uuid) => {
  return api.get(`/back/containers/flux_producers/${uuid}`);
};

// 更新 Flux Producer 状态或信息
export const updateFluxProducer = async (uuid, updateData) => {
  return api.put(`/back/containers/flux_producers/${uuid}`, updateData);
};

// 删除 Flux Producer
export const deleteFluxProducer = async (uuid) => {
  return api.delete(`/back/containers/flux_producers/${uuid}`);
};
