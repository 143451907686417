import React from 'react';

const PlanForm = ({ newPlan, handleNewPlanChange, handleAddPlan, project }) => {
  return (
    <form onSubmit={handleAddPlan} className="new-plan-form">
      <input type="text" name="project" placeholder="Project" value={project} readOnly />
      <input type="text" name="classification_L1" placeholder="Classification L1" value={newPlan.classification_L1} onChange={handleNewPlanChange} required />
      <input type="text" name="classification_L2" placeholder="Classification L2" value={newPlan.classification_L2} onChange={handleNewPlanChange} required />
      <input type="text" name="classification_L3" placeholder="Classification L3 (Optional)" value={newPlan.classification_L3} onChange={handleNewPlanChange} />
      <textarea name="details" placeholder="Details (Optional)" value={newPlan.details} onChange={handleNewPlanChange} className="details-textarea" />
      <input type="text" name="importance" placeholder="Importance (Optional)" value={newPlan.importance} onChange={handleNewPlanChange} />
      <input type="text" name="status" placeholder="Status (Optional)" value={newPlan.status} onChange={handleNewPlanChange} />
      <input type="datetime-local" name="deadline" placeholder="Deadline (Optional)" value={newPlan.deadline} onChange={handleNewPlanChange} />
      <input type="text" name="remark" placeholder="Remark (Optional)" value={newPlan.remark} onChange={handleNewPlanChange} />
      <input type="text" name="PIC" placeholder="PIC (Optional)" value={newPlan.PIC} onChange={handleNewPlanChange} />
      <button type="submit">提交计划</button>
    </form>
  );
};

export default PlanForm;
