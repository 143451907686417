import React, { useState, useRef } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import '@assets/styles/ImageSection.css';
import { updateImage, getFile } from '@services/api';

const ImageSection = ({ images, currentImageIndex, setImages, handleNextImage, handlePreviousImage }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [scale, setScale] = useState(1);
  const [isCropping, setIsCropping] = useState(false);
  const [loading, setLoading] = useState(false); // loading状态
  const cropperRef = useRef(null);

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
    setScale(1);
  };

  const toggleCropping = () => {
    setIsCropping(!isCropping);
  };

  const handleWheel = (event) => {
    if (isFullscreen && !isCropping) {
      event.preventDefault();
      const newScale = scale + event.deltaY * -0.01;
      setScale(Math.min(Math.max(1, newScale), 5));
    }
  };

  const handleCrop = async () => {
    const cropper = cropperRef.current.cropper;
    const croppedCanvas = cropper.getCroppedCanvas();
    const croppedImageBlob = await new Promise((resolve) => croppedCanvas.toBlob(resolve));
    const formData = new FormData();
    formData.append('file', croppedImageBlob, images[currentImageIndex].filename);

    try {
      setLoading(true); // 开始上传，进入loading状态
      await updateImage(images[currentImageIndex].dataset, images[currentImageIndex].filename, formData);
      alert('图片裁剪并更新成功');

      // 重新请求更新后的图片，并加载到当前视图中
      const updatedImageUrl = await getFile(images[currentImageIndex].dataset, images[currentImageIndex].filename);
      const updatedImages = [...images];
      updatedImages[currentImageIndex] = { 
        ...updatedImages[currentImageIndex], 
        url: `${updatedImageUrl}?t=${new Date().getTime()}` // 加时间戳以强制刷新
      };
      setImages(updatedImages);

      setIsCropping(false);
    } catch (error) {
      console.error('图片更新失败', error);
      alert('图片更新失败，请重试');
    } finally {
      setLoading(false); // 上传完成，解除loading状态
    }
  };

  return (
    <div className={`image-container ${isFullscreen ? 'fullscreen' : ''}`} onWheel={handleWheel}>
      <div className="image-section">
        <div className="arrow left-arrow" onClick={handlePreviousImage}>
          ←
        </div>
        {images.length > 0 && (
          isCropping ? (
            <Cropper
              src={images[currentImageIndex]?.url}
              style={{ height: '100%', width: '100%' }}
              guides={false}
              ref={cropperRef}
            />
          ) : (
            <img
              src={images[currentImageIndex]?.url}
              alt="当前图片"
              className="current-image"
              onClick={toggleFullscreen}
              style={{ transform: `scale(${isFullscreen ? 1.2 : 1})` }} // 放大当前图片
            />
          )
        )}
        <div className="arrow right-arrow" onClick={handleNextImage}>
          →
        </div>
      </div>
      {isFullscreen && (
        <>
          <div className="close-fullscreen" onClick={toggleFullscreen}>
            ✕
          </div>
          <button className="crop-button" onClick={toggleCropping} disabled={loading}>
            {isCropping ? '完成裁剪' : '裁剪图片'}
          </button>
          {isCropping && (
            <button className="confirm-crop-button" onClick={handleCrop} disabled={loading}>
              {loading ? '裁剪中...' : '确认裁剪'}
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default ImageSection;
